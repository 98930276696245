import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, Button, Popper, Paper, Fade, List, ListItem, ListItemButton, ClickAwayListener } from '@mui/material';
import LogoSection from '../LogoSection';
import ProfileSection from './ProfileSection';
import ThemeButton from 'ui-component/ThemeButton';
import { IconMenu2 } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { setLanguage } from 'i18n/utils';
import { useSelector } from 'react-redux';
import MainCard from 'ui-component/cards/MainCard';
import TranslateIcon from '@mui/icons-material/Translate';
import { t } from 'i18next';
import { useState } from 'react';
const Header = ({ handleLeftDrawerToggle }) => {
  const siteInfo = useSelector((state) => state.siteInfo);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const { i18n } = useTranslation();
  const switchLanguage = (language) => {
    if (language == i18n.language) {
      return;
    }
    setLanguage(language);
    window.location.reload();
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Box
        sx={{
          width: 228,
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            width: 'auto'
          }
        }}
      >
        <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
          <LogoSection />
        </Box>
        <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              ...theme.typography.menuButton,
              transition: 'all .2s ease-in-out',
              '&:hover': {
                background: theme.palette.secondary.dark,
                color: theme.palette.secondary.light
              }
            }}
            onClick={handleLeftDrawerToggle}
            color="inherit"
          >
            <IconMenu2 stroke={1.5} size="1.3rem" />
          </Avatar>
        </ButtonBase>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />
      <a href={siteInfo.document_address} target="_blank" style={{ marginRight: '10px' }}>
        <Button> {t('document_center')}</Button>
      </a>
      <ButtonBase sx={{ borderRadius: '12px' }} onClick={handleClick}>
        <Avatar
          variant="rounded"
          sx={{
            ...theme.typography.commonAvatar,
            ...theme.typography.mediumAvatar,
            transition: 'all .2s ease-in-out',
            borderColor: theme.typography.menuChip.background,
            backgroundColor: theme.typography.menuChip.background,
            '&[aria-controls="menu-list-grow"],&:hover': {
              background: theme.palette.secondary.dark,
              color: theme.palette.secondary.light
            }
          }}
          onClick={() => {}}
          color="inherit"
        >
          <TranslateIcon fontSize="12px" />
        </Avatar>
      </ButtonBase>
      <Popper
        anchorEl={anchorEl}
        open={open}
        placement="bottom-end"
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                  <List
                    component="nav"
                    sx={{
                      width: '100%',
                      maxWidth: 350,
                      minWidth: 150,
                      backgroundColor: theme.palette.background.paper,
                      borderRadius: '10px',
                      [theme.breakpoints.down('md')]: {
                        minWidth: '100%'
                      },
                      '& .MuiListItemButton-root': {
                        mt: 0.5
                      }
                    }}
                  >
                    <ListItem disablePadding onClick={() => switchLanguage('zh')}>
                      <ListItemButton selected={i18n.language == 'zh'}>{t('chinese')}</ListItemButton>
                    </ListItem>
                    <ListItem disablePadding onClick={() => switchLanguage('en')}>
                      <ListItemButton selected={i18n.language == 'en'}>{t('english')}</ListItemButton>
                    </ListItem>
                  </List>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>

      <ThemeButton />
      <ProfileSection />
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func
};

export default Header;
