// material-ui
import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  Stack,
  Popper,
  IconButton,
  List,
  ListItemButton,
  Paper,
  ListItemText,
  Typography,
  Divider,
  ClickAwayListener,
  ButtonBase,
  Fade,
  Avatar,
  ListItem
} from '@mui/material';
import TranslateIcon from '@mui/icons-material/Translate';
import LogoSection from 'layout/MainLayout/LogoSection';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ThemeButton from 'ui-component/ThemeButton';
import ProfileSection from 'layout/MainLayout/Header/ProfileSection';
import { IconMenu2 } from '@tabler/icons-react';
import Transitions from 'ui-component/extended/Transitions';
import MainCard from 'ui-component/cards/MainCard';
import { useMediaQuery } from '@mui/material';
import { t } from 'i18next';
import { setLanguage } from 'i18n/utils';
import { useTranslation } from 'react-i18next';
const Header = () => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const account = useSelector((state) => state.account);
  const [open, setOpen] = useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = useState(null);
  const [openLang, setOpenLang] = useState(false);
  const { i18n } = useTranslation();
  const handleOpenMenu = (event) => {
    setOpen(open ? null : event.currentTarget);
  };
  const switchLanguage = (language) => {
    if (language == i18n.language) {
      return;
    }
    setLanguage(language);
    window.location.reload();
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenLang(true);
  };
  const handleClose = () => {
    setOpenLang(false);
  };
  const handleCloseMenu = () => {
    setOpen(null);
  };

  return (
    <>
      <Box
        sx={{
          width: 228,
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            width: 'auto'
          }
        }}
      >
        <Box component="span" sx={{ flexGrow: 1 }}>
          <LogoSection />
        </Box>
      </Box>

      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />
      <Stack spacing={2} direction="row" justifyContent="center" alignItems="center">
        {isMobile ? (
          <>
            <ThemeButton />
            <IconButton onClick={handleOpenMenu}>
              <IconMenu2 />
            </IconButton>
          </>
        ) : (
          <>
            <Button component={Link} variant="text" to="/" color={pathname === '/' ? 'primary' : 'inherit'}>
              {t('home')}
            </Button>
            {/* <Button component={Link} variant="text" to="/about" color={pathname === '/about' ? 'primary' : 'inherit'}>
              关于
            </Button> */}
            <ButtonBase sx={{ borderRadius: '12px' }} onClick={handleClick}>
              <Avatar
                variant="rounded"
                sx={{
                  ...theme.typography.commonAvatar,
                  ...theme.typography.mediumAvatar,
                  transition: 'all .2s ease-in-out',
                  borderColor: theme.typography.menuChip.background,
                  backgroundColor: theme.typography.menuChip.background,
                  '&[aria-controls="menu-list-grow"],&:hover': {
                    background: theme.palette.secondary.dark,
                    color: theme.palette.secondary.light
                  }
                }}
                color="inherit"
              >
                <TranslateIcon fontSize="12px" />
              </Avatar>
            </ButtonBase>
            <Popper
              anchorEl={anchorEl}
              open={openLang}
              placement="bottom-end"
              transition
              disablePortal
              popperOptions={{
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, 14]
                    }
                  }
                ]
              }}
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                        <List
                          component="nav"
                          sx={{
                            width: '100%',
                            maxWidth: 350,
                            minWidth: 150,
                            backgroundColor: theme.palette.background.paper,
                            borderRadius: '10px',
                            [theme.breakpoints.down('md')]: {
                              minWidth: '100%'
                            },
                            '& .MuiListItemButton-root': {
                              mt: 0.5
                            }
                          }}
                        >
                          <ListItem disablePadding onClick={() => switchLanguage('zh')}>
                            <ListItemButton selected={i18n.language == 'zh'}>{t('chinese')}</ListItemButton>
                          </ListItem>
                          <ListItem disablePadding onClick={() => switchLanguage('en')}>
                            <ListItemButton selected={i18n.language == 'en'}>{t('english')}</ListItemButton>
                          </ListItem>
                        </List>
                      </MainCard>
                    </ClickAwayListener>
                  </Paper>
                </Fade>
              )}
            </Popper>
            <ThemeButton />
            {account.user ? (
              <>
                <Button component={Link} variant="contained" to="/panel" color="primary">
                  {t('console')}
                </Button>
                <ProfileSection />
              </>
            ) : (
              <Button component={Link} variant="contained" to="/login" color="primary">
                {t('login')}
              </Button>
            )}
          </>
        )}
      </Stack>

      <Popper
        open={!!open}
        anchorEl={open}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14]
              }
            }
          ]
        }}
        style={{ width: '100vw' }}
      >
        {({ TransitionProps }) => (
          <Transitions in={openLang} {...TransitionProps}>
            <ClickAwayListener onClickAway={handleCloseMenu}>
              <Paper style={{ width: '100%' }}>
                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                  <List
                    component="nav"
                    sx={{
                      width: '100%',
                      maxWidth: '100%',
                      minWidth: '100%',
                      backgroundColor: theme.palette.background.paper,

                      '& .MuiListItemButton-root': {
                        mt: 0.5
                      }
                    }}
                    onClick={handleCloseMenu}
                  >
                    <ListItemButton component={Link} variant="text" to="/">
                      <ListItemText primary={<Typography variant="body2">{t('home')}</Typography>} />
                    </ListItemButton>

                    {/* <ListItemButton component={Link} variant="text" to="/about">
                      <ListItemText primary={<Typography variant="body2">关于</Typography>} />
                    </ListItemButton> */}
                    <Divider />
                    {account.user ? (
                      <ListItemButton component={Link} variant="contained" to="/panel" color="primary">
                        {t('console')}
                      </ListItemButton>
                    ) : (
                      <ListItemButton component={Link} variant="contained" to="/login" color="primary">
                        {t('login')}
                      </ListItemButton>
                    )}
                  </List>
                </MainCard>
              </Paper>
            </ClickAwayListener>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default Header;
