const zh = {
  welcome: '欢迎',
  description: '这是一个示例描述',
  todays_request_volume: '今日请求量',
  todays_consumption: '今日消费',
  todays_token: '今日 token',
  public_beta_free: '公测免费',
  number_of_calls: '调用次数',
  information_brief: '信息简报',
  dashboard: '总览',
  statistics: '统计',
  modelsquare: '模型广场',
  exploration_center: '体验中心',
  chat_bot: '聊天对话',
  text2image: '文生图',
  text2language: '文生语音',
  channel: '端点',
  token: '令牌',
  api_keys: '令牌',
  log: '日志',
  redemption: '兑换',
  modelsquare_manage: '模型广场管理',
  modeltag_manage: '模型标签管理',
  user: '用户',
  profile: '我的',
  setting: '设置',
  welcome_back: '欢迎回来',
  todays_active_users: '今日活跃用户',
  todays_sessions: '今日会话次数',
  todays_new_users: '今日新增用户',
  active_user_ranking: '活跃用户排行',
  website_visits: '网站访问量',
  computing_resource_pool: '算力资源池',
  daily_token_throughput: '日Token吞吐量',
  todays_hotspot_model_sessions: '今日热点模型会话统计',
  total_users: '用户总数',
  session_count: '会话次数',
  growth_rate: '增长率',
  daily: '日',
  weekly: '周',
  monthly: '月',
  main_user_index_distribution: '主要用户指数分布',
  thousand_token: '（千Token）',
  token_overview: 'Token总览',
  perfXCloud_data_analysis_platform: 'PerfXCloud 数据分析平台',
  user_common_model_ranking: '用户常用模型排行',
  ranking: '排名',
  username: '用户名',
  balance: '余额',
  user_calls: '用户调用次数',
  used_quota: '已用额度',
  computing_resource_pool: '算力资源池',
  computing_center: '算力中心',
  status: '状态',
  total_tokens: 'Token总量',
  input_tokens: '输入Token',
  output_tokens: '输出Token',
  today_data: '今日数据',
  last_seven_days_data: '近七日数据',
  last_month_data: '近一月数据',
  internal_server_error: '错误：服务器内部错误，请联系管理员！',
  model_types: '模型类型',
  support_chip: '支持芯片',
  hide: '隐藏',
  show: '显示',
  search_model: '搜索您想要查找的模型',
  view_model_details: '查看模型详情',
  trial_available: '可体验',
  operation_successful: '操作成功！',
  testing_all_endpoints: '已成功开始测试所有端点，请刷新页面查看结果。',
  deleted_disabled_endpoints: '已删除所有禁用端点，共计个数',
  updated_enabled_endpoints_balance: '已更新完毕所有已启用端点余额！',
  search_endpoint: '搜索端点的 ID，名称和密钥 ...',
  refresh: '刷新',
  test_enabled_endpoints: '测试启用端点',
  delete_disabled_endpoints: '删除禁用端点',
  create_endpoint: '新建端点',
  name: '名称',
  group: '分组',
  type: '类型',
  status: '状态',
  response_time: '响应时间',
  consumed: '已消耗',
  balance: '余额',
  priority: '优先级',
  actions: '操作',
  baidu_wenxin_qianfan: '百度文心千帆',
  alibaba_tongyi_qianwen: '阿里通义千问',
  iflytek_stellar_cognition: '讯飞星火认知',
  zhipu_chatglm: '智谱 ChatGLM',
  '360_intelligence_brain': '360 智脑',
  tencent_hunyuan: '腾讯混元',
  baichuan_large_model: '百川大模型',
  zero_one_all_things: '零一万物',
  jumpspace_stars: '阶跃星辰',
  custom_endpoint: '自定义端点',
  knowledge_base: '知识库',
  proxy: '代理',
  edit: '编辑',
  delete: '删除',
  edit_endpoint: '编辑端点',
  endpoint_type: '端点类型',
  endpoint_name: '端点名称',
  please_name_endpoint: '请为端点命名',
  endpoint_api_address: '端点API地址',
  optional_transit_api_address: '可空，请输入中转API地址，例如通过Cloudflare中转',
  user_group: '用户组',
  select_user_group: '请选择该端点所支持的用户组',
  model: '模型',
  select_supported_models: '请选择该端点所支持的模型',
  fill_in_supported_models: '填入端点支持模型',
  fill_in_all_models: '填入所有模型',
  key: '密钥',
  enter_auth_key: '请输入端点对应的鉴权密钥',
  model_mapping_relationship: '模型映射关系',
  enter_model_mapping:
    '请输入要修改的模型映射关系，格式为：api请求模型ID:实际转发给端点的模型ID，使用JSON数组表示，例如：{"gpt-3.5": "gpt-35"}',
  priority_cannot_be_negative: '优先级不能小于 0',
  endpoint_test_success: '端点 {{itemName}} 测试成功，耗时 {{time}} 秒。',
  balance_update_success: '余额更新成功！',
  unknown: '未知',
  enabled: '已启用',
  manual_disabled: '本端点被手动禁用',
  auto_disabled: '本端点被程序自动禁用',
  click_update_balance: '点击更新余额',
  delete_endpoint: '删除端点',
  confirm_delete_endpoint: '是否删除端点',
  close: '关闭',
  unsupported: '不支持',
  seconds: '秒',
  last_test_time: '上次测速时间：',
  not_tested: '未测试',
  click_test: '点击测速',
  copy_model_name_success: '复制模型名称成功！',
  name_cannot_be_empty: '名称不能为空',
  endpoint_cannot_be_empty: '端点不能为空',
  key_cannot_be_empty: '密钥不能为空',
  model_cannot_be_empty: '模型不能为空',
  user_group_cannot_be_empty: '用户组不能为空',
  endpoint_api_address_cannot_be_empty: '端点API地址不能为空',
  must_be_valid_json: '必须是有效的JSON字符串',
  endpoint_update_success: '端点更新成功！',
  endpoint_create_success: '端点创建成功！',
  custom_placeholder: '自定义：点击或回车输入',
  edit_endpoint: '编辑端点',
  one_key_per_line: '一行一个密钥',
  batch_add: '批量添加',
  cancel: '取消',
  submit: '提交',
  other_parameters: '其他参数',
  select_endpoint_type: '请选择端点类型',
  name_endpoint: '请为端点命名',
  optional_transfer_api_address: '可空，请输入中转API地址，例如通过cloudflare中转',
  enter_auth_key: '请输入端点对应的鉴权密钥',
  select_supported_models: '请选择该端点所支持的模型',
  enter_model_mapping:
    '请输入要修改的模型映射关系，格式为：api请求模型ID:实际转发给端点的模型ID，使用JSON数组表示，例如：{"gpt-3.5": "gpt-35"}',
  select_supported_user_groups: '请选择该端点所支持的用户组',
  default_api_version: '默认 API 版本',
  fill_azure_openai_endpoint: '请填写AZURE_OPENAI_ENDPOINT',
  enter_default_api_version: '请输入默认API版本，例如：2024-03-01-preview',
  enter_credentials: '按照如下格式输入：APIKey|SecretKey',
  plugin_parameters: '插件参数',
  enter_plugin_parameters: '请输入插件参数，即 X-DashScope-Plugin 请求头的取值',
  version_number: '版本号',
  enter_version_number: '请输入版本号，例如：v3.1',
  enter_credentials_format: '按照如下格式输入：APIKey-AppId，例如：fastgpt-0sp2gtvfdgyi4k30jwlgwf1i-64f335d84283f05518e9e041',
  enter_credentials_format_alt: '按照如下格式输入：AppId|SecretId|SecretKey',
  enter_version_number_alt: '请输入版本号，例如：v1',
  create_token: '新建令牌',
  search_token_name: '搜索令牌的名称...',
  remaining_quota: '剩余额度',
  creation_time: '创建时间',
  expiry_time: '过期时间',
  copied_to_clipboard: '已复制到剪贴板！',
  enabled: '已启用',
  disabled: '已禁用',
  expired: '已过期',
  exhausted: '已耗尽',
  unlimited: '无限制',
  never_expires: '永不过期',
  copy_token: '复制令牌',
  edit_token: '编辑令牌',
  token_quota_note: '注意，令牌的额度仅用于限制令牌本身的最大额度使用量，实际的使用受到账户的剩余额度限制。',
  model_scope: '模型范围',
  model_scope_elect: '请输入模型范围',
  select_allowed_models: '请选择允许使用的模型，留空则不进行限制',
  ip_restriction: 'IP 限制',
  enter_allowed_ip_ranges: '请输入允许访问的网段，例如：192.168.0.0/24，请使用英文逗号分隔多个网段',
  invalid_date: '无效的日期',
  quota: '额度',
  unlimited_quota: '无限额度',
  name_required: '名称 不能为空',
  must_be_greater_than_or_equal_to_zero: '必须大于等于0',
  token_update_success: '令牌更新成功！',
  token_create_success: '令牌创建成功，请在列表页面点击复制获取令牌！',
  never_expires: '永不过期',
  equivalent_amount: '等价金额',
  token_name: '令牌名称',
  model_name: '模型名称',
  start_time: '起始时间',
  end_time: '结束时间',
  endpoint_id: '端点ID',
  user_name: '用户名称',
  refresh_clear_search: '刷新/清除搜索条件',
  search: '搜索',
  all: '全部',
  recharge: '充值',
  consume: '消费',
  manage: '管理',
  system: '系统',
  clear: '清除',
  today: '今天',
  time: '时间',
  must_be_greater_than_or_equal_to_1: '必须大于等于1',
  coupon_code_update_success: '兑换码更新成功！',
  coupon_code_create_success: '兑换码创建成功！',
  edit_coupon_code: '编辑兑换码',
  create_coupon_code: '新建兑换码',
  search_coupon_code_id_name: '搜索兑换码的ID和名称...',
  redeem_time: '兑换时间',
  used: '已使用',
  not_yet_redeemed: '尚未兑换',
  delete_coupon_code: '删除兑换码',
  confirm_delete_coupon_code: '是否删除兑换码{{itemName}}？',
  quantity: '数量',
  create_model: '新建模型',
  search_user: '搜索用户的ID，用户名，显示名称，以及邮箱地址...',
  delete_success: '删除成功！',
  model_tag: '模型标签',
  model_release_time: '模型发布时间',
  sort: '排序',
  is_trial: '是否体验',
  is_disabled: '是否禁用',
  category: '分类',
  requested_model_name: '请求的模型名称',
  model_config: '模型配置',
  delete_user_confirmation: '是否删除用户 {{name}}？',
  delete_model_confirmation: '是否删除模型 {{name}}？',
  delete_model_tag_confirmation: '是否删除模型标签 {{name}}？',
  delete_user: '删除用户',
  delete_model: '删除模型',
  delete_model_tag: '删除模型标签',
  model_config_update_success: '模型配置更新成功！',
  load_model_config_failed: '加载模型配置失败',
  edit_model_config: '编辑模型配置',
  edit_model: '编辑模型',
  load_model_failed: '加载模型失败',
  model_name_cannot_be_empty: '模型名称不能为空',
  release_date_cannot_be_empty: '发布时间不能为空',
  description_cannot_be_empty: '描述不能为空',
  model_details_cannot_be_empty: '模型详情不能为空',
  model_update_successful: '模型更新成功！',
  model_creation_successful: '模型创建成功！',
  release_time: '模型发布时间',
  select_label: '选择标签',
  model_category: '模型分类',
  search_model_tag_name: '搜索模型标签的名称...',
  tag_name_min_length: '标签名称必须至少为1个字符',
  tag_name_required: '标签名称不能为空',
  tag_type_required: '标签类型不能为空',
  tag_value_required: '标签值不能为空',
  enter_tag_name: '请输入标签名称',
  enter_tag_type: '请输入标签类型',
  tag_update_success: '标签更新成功！',
  tag_create_success: '标签创建成功！',
  edit_tag: '编辑标签',
  new_tag: '新建标签',
  tag_name: '标签名称',
  tag_value: '标签值',
  tag_type: '标签类型',
  regular_user: '普通用户',
  admin: '管理员',
  super_admin: '超级管理员',
  unknown_identity: '未知身份',
  create_user: '新建用户',
  search_users_placeholder: '搜索用户的ID，用户名，显示名称，以及邮箱地址...',
  statistics_info: '统计信息',
  user_roles: '用户角色',
  binding: '绑定',
  request_count: '请求次数',
  unbound: '未绑定',
  set_as_admin: '设为管理员',
  cancel_admin: '取消管理员',
  edit_user: '编辑用户',
  display_name: '显示名称',
  password: '密码',
  username_min_length: '用户名长度必须至少为3个字符',
  username_required: '用户名 不能为空',
  password_min_length: '密码长度必须至少为8个字符',
  password_required: '密码 不能为空',
  user_group_required: '用户组 不能为空',
  quota_non_negative: '额度 不能小于 0',
  enter_username: '请输入用户名',
  enter_password: '请输入密码',
  user_update_success: '用户更新成功！',
  user_creation_success: '用户创建成功！',
  wechat_binding_success: '微信账户绑定成功！',
  token_reset_and_copied: '令牌已重置并已复制到剪贴板',
  user_info_update_success: '用户信息更新成功！',
  bind_wechat_account: '绑定微信账号',
  bind_github_account: '绑定 GitHub 账号',
  account_binding: '账号绑定',
  enter_display_name: '请输入显示名称',
  note_token_usage: '注意，此处生成的令牌用于系统管理，而非用于请求 PerfXCloud 相关的服务，请知悉。',
  your_access_token: '你的访问令牌是:',
  secure_storage_warning: '请妥善保管。如有泄漏，请立即重置。',
  reset_access_token: '重置访问令牌',
  generate_access_token: '生成访问令牌',
  dangerous_operation: '危险操作',
  delete_account_warning: '您正在删除自己的帐户，将清空所有数据且不可恢复',
  confirm: '确定',
  change_phone_number: '更换手机号',
  bind_phone_number: '绑定手机号',
  personal_information: '个人信息',
  bind_feishu_account: '绑定飞书账号',
  change_email: '更换邮箱',
  bind_email: '绑定邮箱',
  other: '其他',
  please_enter_valid_email: '请输入正确的邮箱地址',
  email_cannot_be_empty: '邮箱不能为空',
  verification_code_cannot_be_empty: '验证码不能为空',
  email_binding_successful: '邮箱账户绑定成功！',
  please_enter_email: '请输入邮箱',
  email: '邮箱',
  resend_countdown: '重新发送({{countdown}})',
  get_verification_code: '获取验证码',
  verification_code: '验证码',
  error_too_many_requests: '错误：请求次数过多，请稍后再试！',
  error: '错误：',
  go_to_first_page: '前往第一页',
  go_to_last_page: '前往最后一页',
  go_to_next_page: '前往下一页',
  go_to_previous_page: '前往上一页',
  phone_number_format_invalid: '手机号格式不正确',
  phone_number_required: '手机号是必填项',
  verification_code_required: '验证码不能为空',
  phone_number_bound_successfully: '手机号绑定成功！',
  please_enter_phone_number: '请输入手机号',
  phone_number: '手机号',
  log_settings: '日志设置',
  enable_log_consumption: '启用日志消费',
  clear_history_logs: '清理历史日志',
  log_cleanup_time: '日志清理时间',
  monitoring_settings: '监控设置',
  disable_endpoint_on_failure: '失败时自动禁用端点',
  enable_endpoint_on_success: '成功时自动启用端点',
  max_response_time: '最长响应时间',
  quota_alert_threshold: '额度提醒阈值',
  unit_seconds: '单位：秒',
  endpoint_test_timeout_message: '当运行端点全部测试时，超过此时间将自动禁用端点',
  quota_alert_message: '低于此额度时将发送邮件提醒用户',
  save_monitoring_settings: '保存监控设置',
  save_quota_settings: '保存额度设置',
  quota_settings: '额度设置',
  new_user_initial_quota: '新用户初始额度',
  pre_deducted_request_quota: '请求预扣费额度',
  invite_new_user_reward_quota: '邀请新用户奖励额度',
  reward_quota_for_invite_code: '新用户使用邀请码奖励额度',
  multiplier_settings: '倍率设置',
  model_multiplier: '模型倍率',
  completion_multiplier: '补全倍率',
  group_multiplier: '分组倍率',
  settings_success: '设置成功！',
  invalid_model_multiplier: '模型倍率不是合法的 JSON 字符串',
  invalid_group_multiplier: '分组倍率不是合法的 JSON 字符串',
  invalid_completion_multiplier: '补全倍率不是合法的 JSON 字符串',
  save_success: '保存成功！',
  logs_cleared: '已清理 {{data}} 条日志！',
  logs_clear_failure: '日志清理失败：',
  general_settings: '通用设置',
  recharge_link: '充值链接',
  example_recharge_link: '例如发卡网站的购买链接',
  example_deployment_link: '例如 ChatGPT Next Web 的部署地址',
  unit_quota: '单位额度',
  currency_to_quota: '一单位货币能兑换的额度',
  retry_count: '重试次数',
  display_as_currency: '以货币形式显示额度',
  billing_api_token_quota: 'Billing 相关 API 显示令牌额度而非用户额度',
  approximate_token_estimation: '使用近似的方式估算 token 数以减少计算量',
  save_general_settings: '保存通用设置',
  operation_settings: '运营设置',
  system_settings: '系统设置',
  other_settings: '其他设置',
  chat_link: '聊天链接',
  unit_seconds_auto_disable: '单位秒，当运行端点全部测试时，超过此时间将自动禁用端点',
  example_100: '例如：100',
  example_2000: '例如：2000',
  example_1000: '例如：1000',
  example: '例如：',
  request_adjustment: '请求结束后多退少补',
  json_text_example: '为一个 JSON 文本，键为模型名称，值为倍率',
  json_text_example_description:
    '为一个 JSON 文本，键为模型名称，值为倍率，此处的倍率设置是模型补全倍率相较于提示倍率的比例，使用该设置可强制覆盖 One API 的内部比例',
  json_text_group_multiplier: '为一个 JSON 文本，键为分组名称，值为倍率',
  save_multiplier_settings: '保存倍率设置',
  chinese: '中文',
  english: '英文',
  document_center: '文档中心',
  logout: '登出',
  document_address: '文档中心地址',
  server_address: '服务器地址',
  update_server_address: '更新服务器地址',
  update_document_address: '更新文档中心地址',
  configure_login_registration: '配置登录注册',
  allow_password_login: '允许通过密码进行登录',
  password_registration_email_verification: '通过密码注册时需要进行邮箱验证',
  allow_github_login_registration: '允许通过 GitHub 账户登录 & 注册',
  allow_google_login_registration: '允许通过 Google 账户登录 & 注册',
  allow_wechat_login_registration: '允许通过微信登录 & 注册',
  allow_new_user_registration: '允许新用户注册（此项为否时，新用户将无法以任何方式进行注册）',
  enable_turnstile_verification: '启用 Turnstile 用户校验',
  enable_phone_verification_login_registration: '启用 手机验证码登录 & 注册',
  configure_email_domain_whitelist: '配置邮箱域名白名单',
  prevent_temporary_email_registration: '用以防止恶意用户利用临时邮箱批量注册',
  enable_email_domain_whitelist: '启用邮箱域名白名单',
  allowed_email_domains: '允许的邮箱域名',
  save_email_domain_whitelist_settings: '保存邮箱域名白名单设置',
  configure_smtp: '配置 SMTP',
  support_email_sending: '用以支持系统的邮件发送',
  smtp_server_address: 'SMTP 服务器地址',
  smtp_port: 'SMTP 端口',
  smtp_default: '默认:',
  smtp_account: 'SMTP 账户',
  smtp_account_description: '通常是邮箱地址',
  smtp_sender_email: 'SMTP 发送者邮箱',
  smtp_sender_email_description: '通常和邮箱地址保持一致',
  smtp_credentials: 'SMTP 访问凭证',
  sensitive_info_hidden_description: '敏感信息不会发送到前端显示',
  save_smtp_settings: '保存 SMTP 设置',
  configure_github_oauth: '配置 GitHub OAuth App',
  configure_google_oauth: '配置 Google OAuth App',
  input_github_oauth_id: '输入你注册的 GitHub OAuth APP 的 ID',
  save_github_oauth_settings: '保存 GitHub OAuth 设置',
  save_google_oauth_settings: '保存 Google OAuth 设置',
  configure_feishu_oauth: '配置飞书授权登录',
  input_feishu_oauth_homepage: '主页链接填',
  input_feishu_oauth_redirect_url: '重定向 URL 填',
  save_feishu_oauth_settings: '保存飞书 OAuth 设置',
  configure_wechat_oauth_login: '配置微信授权登录',
  support_wechat_login_registration: '用以支持通过微信进行登录注册，',
  allow_password_registration: '允许通过密码进行注册',
  github_oauth_support_description: '用以支持通过 GitHub 进行登录注册',
  google_oauth_support_description: '用以支持通过 Google 进行登录注册',
  click_here: '点击此处',
  manage_your_github_oauth_app: '管理你的 GitHub OAuth App',
  manage_your_google_oauth_app: '管理你的 Google OAuth App',
  fill_in: '填',
  support_feishu_login_register: '用以支持通过飞书进行登录注册，',
  manage_feishu_app: '管理你的飞书应用。',
  input_app_id: '输入 App ID',
  configure: '配置',
  learn_more: '了解',
  access_credential: '访问凭证',
  wechat_official_account_qr_code: '微信公众号二维码图片链接',
  input_image_link: '输入一个图片链接',
  save_wechat_server_settings: '保存 WeChat Server 设置',
  alert_push_info: '用以推送报警信息，',
  message_pusher_address: 'Message Pusher 推送地址',
  save_message_pusher_settings: '保存 Message Pusher 设置',
  user_verification_info: '用以支持用户校验，',
  manage_turnstile_sites: '管理你的 Turnstile Sites，推荐选择 Invisible Widget Type',
  input_turnstile_site_key: '输入你注册的 Turnstile Site Key',
  save_turnstile_settings: '保存 Turnstile 设置',
  disable_password_login_warning: '取消密码登录将导致所有未绑定其他登录方式的用户（包括管理员）无法通过密码登录，确认取消？',
  warning: '警告',
  emergency_notice: '紧急通告',
  maintenance_notice: '维护通知',
  promotion_notice: '优惠通告',
  policy_notice: '政策通告',
  latest_version: '已是最新版本：',
  check_update: '检查更新',
  announcement_type: '公告类型',
  announcement: '公告',
  enter_new_announcement_content: '在此输入新的公告内容，支持 Markdown & HTML 代码',
  save_announcement: '保存公告',
  personalized_settings: '个性化设置',
  system_name: '系统名称',
  enter_system_name: '在此输入系统名称',
  set_system_name: '设置系统名称',
  theme_name: '主题名称',
  enter_theme_name: '请输入主题名称',
  set_theme: '设置主题（重启生效）',
  logo_image_address: 'Logo 图片地址',
  enter_logo_image_address: '在此输入Logo 图片地址',
  set_logo: '设置 Logo',
  homepage_content: '首页内容',
  enter_homepage_content:
    '在此输入首页内容，支持 Markdown & HTML 代码，设置后首页的状态信息将不再显示。如果输入的是一个链接，则会使用该链接作为 iframe 的 src 属性，这允许你设置任意网页作为首页。',
  save_homepage_content: '保存首页内容',
  about: '关于',
  enter_about_content:
    '在此输入新的关于内容，支持 Markdown & HTML 代码。如果输入的是一个链接，则会使用该链接作为 iframe 的 src 属性，这允许你设置任意网页作为关于页面。',
  save_about: '保存关于',
  remove_copyright_notice: '移除 One API 的版权标识必须首先获得授权，项目维护需要花费大量精力，如果本项目对你有意义，请主动支持本项目。',
  footer: '页脚',
  enter_new_footer: '在此输入新的页脚，留空则使用默认页脚，支持 HTML 代码',
  set_footer: '设置页脚',
  model_api_configuration: '模型API配置设置',
  public_token: 'public 令牌',
  enter_public_token: '请输入public 令牌',
  model_api_access_address: '模型API访问地址',
  enter_model_api_access_address: '请输入模型API访问地址',
  chatbot_access_address: 'ChatBot访问地址',
  enter_chatbot_access_address: '请输入ChatBot访问地址',
  model_configuration_settings: '模型配置设置',
  new_version: '新版本：',
  view_on_github: '去GitHub查看',
  public_security: '京公网安备:11010802038875号',
  icp_record: '京ICP备:2020035859号-1',
  copyright: 'Copyright © Perfxlab 澎峰科技 All Rights Reserved',
  home: '首页',
  phone_login: '手机号登录',
  account_login: '账户登录',
  console: '控制台',
  login: '登录',
  password_reset: '密码重置',
  processing: '处理中...',
  operation_failed_redirecting_to_login: '操作失败，重定向至登录界面中...',
  retrying_message: '出现错误，第 {{count}} 次重试中...',
  github_login: 'GitHub 登录',
  google_login: 'Google 登录',
  feishu_login: '飞书 登录',
  register: '注册',
  email_register: '邮箱注册',
  already_have_account: '已经有帐号了？点击登录',
  phone_register: '手机号注册',
  password_reset_confirm: '密码重置确认',
  feishu_login: '飞书 登录',
  phone_login: '手机号登录',
  register: '注册',
  already_have_account: '已经有帐号了？点击登录',
  phone_register: '手机号注册',
  password_reset_confirm: '密码重置确认',
  login_with_github: '使用 GitHub 登录',
  login_with_wechat: '使用微信登录',
  login_with_google: '使用Google登录',
  login_with_feishu: '使用飞书登录',
  username_length: '用户名长度必须为3个字符~40个字符',
  username_required: '用户名是必填项',
  password_length: '密码长度必须为8个字符~20个字符',
  password_required: '密码是必填项',
  agree_to_terms: '请先同意用户协议',
  username_email_phone: '用户名 / 邮箱 /手机号',
  agree_and_read: '我已阅读并同意',
  user_agreement: '用户协议',
  forgot_password: '忘记密码?',
  enter_phone_number: '请输入手机号',
  phone_verification_required: '验证码是必填项',
  unregistered_phone_creates_account: '未注册的手机号将自动创建账号',
  passwords_do_not_match: '两次输入的密码不一致',
  retry_turnstile: '请稍后几秒重试，Turnstile 正在检查用户环境！',
  confirm_password_required: '确认密码是必填项',
  invalid_email: '必须是有效的Email地址',
  email_required: 'Email是必填项',
  confirm_password: '确认密码',
  start_your_exclusive_experience: '开启您的专属体验，从',
  start: '开始。',
  please_enter_phone_number: '请输手机号',
  phone_verification_code_required: '手机验证码是必填项',
  reset_email_sent_success: '重置邮件发送成功，请检查邮箱！',
  enter_verification_code: '请输入验证码',
  enter_reset_password: '请输入需要重置的密码',
  reset_password_success: '重置密码成功！',
  reset_sms_code_success: '重置短信验证码发送成功，请检查手机短信！',
  sms_code_success: '短信验证码发送成功，请检查手机短信！',
  email_code_success: '邮件发送成功，请检查邮箱！',
  invalid_phone_number: '必须是有效的手机号',
  new_password: '新密码',
  retry: '重试 ({{countdownTime}})',
  newPasswordCopied: '新密码已复制到剪贴板：',
  invalidLink: '无效的链接',
  yourNewPasswordIs: '你的新密码是: ',
  pleaseLoginChangePassword: '请登录后及时修改密码',
  clickResetPassword: '点击重置密码',
  supported_chip_types: '支持芯片的类型',
  model_description: '模型介绍',
  go_back: '返回上一页',
  total_models: '模型总数',
  large_language_model: '大语言模型',
  embedding_model: 'Embedding 模型',
  changsha_shuguang_center: '长沙曙光中心',
  xian_shuguang_center: '西安曙光中心',
  dianjun_center: '点军中心',
  tianjin_center: '天津中心',
  wuhan_center: '武汉中心',
  gansu_center: '甘肃中心',
  neimenggu_center: '内蒙古中心',
  online: '在线',
  offline: '离线',
  popular_models_recommendation_large_language_models: '热门模型推荐（语言大模型）',
  no_search_results: '没有搜索结果',
  adjust_search_or_filters: '试着调整你的搜索或过滤器来找到你要找的东西。',
  clear_filters: '清空筛选',
  disclaimer_title: '免责声明',
  disclaimer_content: `限于技术特性，平台在尽到合理努力的前提下，仍无法完全保证您通过本服务获得的输出的合法性、真实性、准确性以及完整性。您应对本服务中的输出自行加以判断，并承担因使用内容引起的所有风险。
            我们提示您，除法律有明确规定外，平台无法也不会对前述风险而导致的任何损失或损害承担责任。本服务下的输出不代表专业意见，不能代替如法律、医疗、金融等专业领域从业人员向您解答对应疑问。相关观点也仅限于算法所触达有限数据呈现的供您参考的结果，并不代表平台立场。
            由于机器学习特性，就相同的输入可能产生不同的输出结果，请您注意甄别。`,
  tryNow: '立即体验',
  copy_code: '复制代码',
  code_copied_successfully: '代码复制成功！',
  register_success: '注册成功！',
  bindingSuccess: '绑定成功！',
  loginSuccess: '登录成功！',
  user_unauthorized: '用户未授权',
  pleaseEnterContent: '请输入内容',
  no_data: '无数据',
  top_up_center: '充值',
  modelContextLength: '模型上下文长度',
  rechargeAndInviteLogs: '充值记录以及邀请记录请在日志中查询。充值记录请在日志中选择类型【充值】查询；邀请记录请在日志中选择【系统】查询',
  currentBalance: '当前额度',
  redemptionCode: '兑换码',
  pleaseEnterRedemptionCode: '请输入兑换码',
  redeeming: '兑换中...',
  redeem: '兑换',
  noRedemptionCode: '还没有兑换码？ 点击获取兑换码：',
  getRedemptionCode: '获取兑换码',
  adminNoRechargeLink: '超级管理员未设置充值链接！',
  requestFailed: '请求失败',
  rechargeSuccessful: '充值成功！',
  pleaseEnterRechargeCode: '请输入充值码！',
  'menus.panelMenus.image': '图片',
  'menus.panelMenus.link': '链接',
  'menus.panelMenus.code': '代码',
  'menus.panelMenus.video': '视频',
  'menus.panelMenus.table': '表格',
  'menus.panelMenus.emotion': '表情',
  'menus.panelMenus.math': '数学公式',
  'menus.panelMenus.lineHeight': '行高',
  'menus.panelMenus.fontFamily': '字体',
  'menus.panelMenus.fontSize': '字号',
  'menus.panelMenus.justify': '对齐',
  'menus.panelMenus.textStyle': '样式',
  order_manage: '订单管理',
  order_number: '订单号',
  recharge_amount: '充值金额',
  token_balance: 'Token余额',
  order_status: '订单状态',
  rejection_reason: '退款拒绝原因',
  order_status_pending: '待支付',
  order_status_paid_success: '支付成功',
  order_status_paid_failed: '支付失败',
  order_status_cancelled: '取消支付',
  order_status_refund_request: '退款审核中',
  order_status_refunding: '退款中',
  order_status_refund_success: '退款成功',
  order_status_refund_failed: '退款失败',
  all: '全部',
  select_purchase_method: '选择购买方式',
  choose_appropriate_way: '请选择一种合适的方式来获取Token',
  purchase_token: '购买Token',
  redeem_token: '兑换Token',
  refund: '申请退款',
  agree_refund: '同意退款',
  refusal_refund: '拒绝退款',
  refund_approved: '已同意退款，正在退款处理中...',
  refund_request_success: '申请退款成功',
  refund_enter_reason: '请输入退款原因',
  refund_request: '申请退款',
  refund_reason: '退款原因',
  refund_confirm_approve: '是否同意退款？',
  refund_reject: '拒绝退款',
  refund_reject_reason: '拒绝退款原因',
  refund_enter_reject_reason: '请输入拒绝退款原因',
  refund_reject_success: '拒绝退款成功',
  pay_amount: '支付金额',
  recharge: '充值',
  view_price: '查看价格',
  pay_method: '支付方式',
  token_tips: '根据最新输出价格预估，仅供参考，以实际调用时的价格为准',
  bank: '银行卡',
  custom: '自定义',
  card_number: '卡号',
  about_equal: '约等于',
  expiration_date: '有效期',
  openAI_multimodal: 'OpenAI 多模态',
  not_enough_quota: '退款余额不足'
};

export default zh;
