import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import AuthGuard from 'utils/route-guard/AuthGuard';
import NationalEmblemImg from 'assets/images/national_emblem.png';
// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery, Grid, Link } from '@mui/material';
// import AdminContainer from 'ui-component/AdminContainer';
// import FloatingActionButtonSize from './FloatingActionButton';
// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import navigation from 'menu-items';
import { drawerWidth } from 'store/constant';
import { SET_MENU } from 'store/actions';
import { useHeaderVisibility } from '../../hooks/useHeaderVisibility';

// assets
import { IconChevronRight } from '@tabler/icons-react';
import { t } from 'i18next';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  ...theme.typography.mainContent,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  transition: theme.transitions.create(
    'margin',
    open
      ? {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen
        }
      : {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        }
  ),
  [theme.breakpoints.up('md')]: {
    marginLeft: open ? 0 : -(drawerWidth - 20),
    width: `calc(100% - ${drawerWidth}px)`
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: '20px',
    width: `calc(100% - ${drawerWidth}px)`,
    padding: '16px'
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: '10px',
    width: `calc(100% - ${drawerWidth}px)`,
    padding: '16px',
    marginRight: '10px'
  }
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const shouldDisplayHeader = useHeaderVisibility();
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  // Handle left drawer
  const leftDrawerOpened = useSelector((state) => state.customization.opened);
  const dispatch = useDispatch();
  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* header */}
      {shouldDisplayHeader && (
        <AppBar
          enableColorOnDark
          position="fixed"
          color="inherit"
          elevation={0}
          sx={{
            bgcolor: theme.palette.background.default,
            transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
          }}
        >
          <Toolbar>
            <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
          </Toolbar>
        </AppBar>
      )}
      {/* drawer */}
      <Sidebar drawerOpen={!matchDownMd ? leftDrawerOpened : !leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />
      {/* main content */}
      <Main theme={theme} open={leftDrawerOpened} style={{ marginTop: shouldDisplayHeader ? '' : '80px' }}>
        {/* <FloatingActionButtonSize /> */}
        <div style={{ minHeight: 'calc(100vh - 164px)', display: 'flex', flexDirection: 'column' }}>
          {/* breadcrumb */}
          <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
          <AuthGuard>
            {/* <AdminContainer>
              <Outlet />
            </AdminContainer> */}
            <Grid flex={1} style={{ display: 'flex', flexDirection: 'column' }}>
              <Outlet />
            </Grid>
          </AuthGuard>
        </div>
        <Grid
          sx={{
            marginBottom: '40px',
            display: 'flex',
            justifyContent: 'center',
            [theme.breakpoints.up('md')]: {
              flexDirection: 'row',
              marginBottom: '0px'
            }
          }}
          style={{
            height: '15px',
            lineHeight: '15px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '20px',
            flexWrap: 'wrap',
            fontSize: '12px'
          }}
        >
          <span>{t('copyright')}</span>
        </Grid>
      </Main>
    </Box>
  );
};

export default MainLayout;
