import { API } from 'utils/api';
import { useDispatch } from 'react-redux';
import { LOGIN } from 'store/actions';
import { useNavigate } from 'react-router';
import { showError, showSuccess } from 'utils/common';
import { t } from 'i18next';
const useLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const login = async (username, password) => {
    try {
      const res = await API.post(`/api/user/login`, {
        username,
        password
      });
      const { success, message, data } = res.data;
      if (success) {
        localStorage.setItem('user', JSON.stringify(data));
        try {
          const modelConfigRes = await API.get('/api/model_config/list');
          if (modelConfigRes.data.success) {
            if (modelConfigRes.data.data && modelConfigRes.data.data.length) {
              localStorage.setItem('model_config', JSON.stringify((modelConfigRes.data.data || [])[0] || {}));
            }
          }
        } catch (error) {
          console.error(error);
        }
        dispatch({ type: LOGIN, payload: data });
        navigate('/panel/dashboard');
      }
      return { success, message };
    } catch (err) {
      // 请求失败，设置错误信息
      return { success: false, message: '' };
    }
  };

  const githubLogin = async (code, state) => {
    try {
      if(!code){
        return {success:false,message:t('user_unauthorized')};
      }
      const res = await API.get(`/api/oauth/github?code=${code}&state=${state}`);
      const { success, message, data } = res.data;
      if (success) {
        if (message === 'bind') {
          showSuccess(t('bindingSuccess'));
          navigate('/panel/dashboard');
        } else {
          dispatch({ type: LOGIN, payload: data });
          localStorage.setItem('user', JSON.stringify(data));
          showSuccess(t('loginSuccess'));
          navigate('/panel/dashboard');
        }
      }
      return { success, message };
    } catch (err) {
      // 请求失败，设置错误信息
      return { success: false, message: '' };
    }
  };

  const googleLogin = async (code, state) => {
    if(!code){
      return {success:false,message:t('user_unauthorized')};
    }
    try {
      const res = await API.get(`/api/oauth/google?code=${code}&state=${state}`);
      const { success, message, data } = res.data;
      if (success) {
        if (message === 'bind') {
          showSuccess(t('bindingSuccess'));
          navigate('/panel/dashboard');
        } else {
          dispatch({ type: LOGIN, payload: data });
          localStorage.setItem('user', JSON.stringify(data));
          showSuccess(t('loginSuccess'));
          navigate('/panel/dashboard');
        }
      }
      return { success, message };
    } catch (err) {
      // 请求失败，设置错误信息
      return { success: false, message: '' };
    }
  };

  const larkLogin = async (code, state) => {
    try {
      const res = await API.get(`/api/oauth/lark?code=${code}&state=${state}`);
      const { success, message, data } = res.data;
      if (success) {
        if (message === 'bind') {
          showSuccess(t('bindingSuccess'));
          navigate('/panel/dashboard');
        } else {
          dispatch({ type: LOGIN, payload: data });
          localStorage.setItem('user', JSON.stringify(data));
          showSuccess(t('loginSuccess'));
          navigate('/panel/dashboard');
        }
      }
      return { success, message };
    } catch (err) {
      // 请求失败，设置错误信息
      return { success: false, message: '' };
    }
  };

  const wechatLogin = async (code) => {
    try {
      const res = await API.get(`/api/oauth/wechat?code=${code}`);
      const { success, message, data } = res.data;
      if (success) {
        dispatch({ type: LOGIN, payload: data });
        localStorage.setItem('user', JSON.stringify(data));
        showSuccess(t('loginSuccess'));
        navigate('/panel/dashboard');
      }
      return { success, message };
    } catch (err) {
      // 请求失败，设置错误信息
      return { success: false, message: '' };
    }
  };

  const logout = async () => {
    await API.get('/api/user/logout');
    let userinfo = {};
    try {
      userinfo = JSON.parse(localStorage.getItem('user'));
    } catch (error) {
      console.log(error);
    }
    localStorage.removeItem('user');
    if (userinfo?.isSuperLogin) {
      window.location.href = 'https://www.scnet.cn/ui/app/platform';
    } else {
      dispatch({ type: LOGIN, payload: null });
    }
    navigate('/');
  };
  const phoneLogin = async (phone, sms_code) => {
    try {
      const res = await API.post(`/api/user/phone/login`, {
        phone,
        sms_code
      });
      const { success, message, data } = res.data;
      if (success) {
        localStorage.setItem('user', JSON.stringify(data));
        try {
          const modelConfigRes = await API.get('/api/model_config/list');
          if (modelConfigRes.data.success) {
            if (modelConfigRes.data.data && modelConfigRes.data.data.length) {
              localStorage.setItem('model_config', JSON.stringify((modelConfigRes.data.data || [])[0] || {}));
            }
          }
        } catch (error) {
          console.error(error);
        }
        dispatch({ type: LOGIN, payload: data });
        navigate('/panel/dashboard');
      }
      return { success, message };
    } catch (err) {
      // 请求失败，设置错误信息
      return { success: false, message: '' };
    }
  };
  const superLogin = async (token) => {
    try {
      const res = await API.get(`/api/user/super/login?token=${token}`);
      const { success, message, data } = res.data;
      if (success) {
        localStorage.setItem('user', JSON.stringify({ ...data, isSuperLogin: true }));
        try {
          const modelConfigRes = await API.get('/api/model_config/list');
          if (modelConfigRes.data.success) {
            if (modelConfigRes.data.data && modelConfigRes.data.data.length) {
              localStorage.setItem('model_config', JSON.stringify((modelConfigRes.data.data || [])[0] || {}));
            }
          }
        } catch (error) {
          console.error(error);
        }
        dispatch({ type: LOGIN, payload: data });
        navigate('/panel/dashboard');
      }
      return { success, message };
    } catch (err) {
      // 请求失败，设置错误信息
      return { success: false, message: '' };
    }
  };
  return { login, logout, githubLogin,googleLogin, wechatLogin, larkLogin, phoneLogin, superLogin };
};

export default useLogin;
