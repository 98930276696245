const en = {
  welcome: 'Welcome',
  description: 'This is an example description',
  todays_request_volume: "Today's Request Volume",
  todays_consumption: "Today's Consumption",
  todays_token: "Today's Token",
  public_beta_free: 'Public Beta Free',
  number_of_calls: 'Number of Calls',
  information_brief: 'Information Brief',
  dashboard: 'Dashboards',
  statistics: 'Statistics (already plural)',
  modelsquare: 'Model Squares',
  exploration_center: 'Exploration Centers',
  chat_bot: 'ChatBot',
  text2image: 'Text Generation pictures',
  text2language: 'Text Generation Language',
  channel: 'Endpoints',
  token: 'API Keys',
  api_keys: 'API Keys',
  log: 'Logs',
  redemption: 'Redemptions',
  modelsquare_manage: 'Model Square Managements',
  modeltag_manage: 'Model Tag Managements',
  user: 'Users',
  profile: 'Profiles',
  setting: 'Settings',
  welcome_back: 'Welcome Back',
  todays_active_users: "Today's Active Users",
  todays_sessions: "Today's Sessions",
  todays_new_users: "Today's New Users",
  active_user_ranking: 'Active User Ranking',
  website_visits: 'Website Visits',
  computing_resource_pool: 'Computing Resource Pool',
  daily_token_throughput: 'Daily Token Throughput',
  todays_hotspot_model_sessions: "Today's Hotspot Model Sessions",
  total_users: 'Total Users',
  session_count: 'Session Count',
  growth_rate: 'Growth Rate',
  daily: 'Daily',
  weekly: 'Weekly',
  monthly: 'Monthly',
  main_user_index_distribution: 'Main User Index Distribution',
  thousand_token: '(Thousand Tokens)',
  token_overview: 'Token Overview',
  perfXCloud_data_analysis_platform: 'PerfXCloud Data Analysis Platform',
  user_common_model_ranking: 'User Common Model Ranking',
  ranking: 'Ranking',
  username: 'Username',
  balance: 'Balance',
  user_calls: 'User Calls',
  used_quota: 'Used Quota',
  computing_resource_pool: 'Computing Resource Pool',
  computing_center: 'Computing Center',
  status: 'Status',
  total_tokens: 'Total Tokens',
  input_tokens: 'Input Tokens',
  output_tokens: 'Output Tokens',
  today_data: "Today's Data",
  last_seven_days_data: "Last 7 Days' Data",
  last_month_data: "Last Month's Data",
  internal_server_error: 'Error:Internal server error, please contact the administrator!',
  model_types: 'Model types',
  support_chip: 'Support chip',
  hide: 'Hide',
  show: 'Show',
  search_model: 'Search the model you want to find',
  view_model_details: 'View model details',
  trial_available: 'Trial available',
  operation_successful: 'Operation successful!',
  testing_all_endpoints: 'Successfully started testing all endpoints, please refresh the page to see the results.',
  deleted_disabled_endpoints: 'Deleted all disabled endpoints, total: ',
  updated_enabled_endpoints_balance: 'All enabled endpoints balance has been updated!',
  search_endpoint: 'Search endpoint ID, name, and key ...',
  refresh: 'Refresh',
  test_enabled_endpoints: 'Test enabled endpoints',
  delete_disabled_endpoints: 'Delete disabled endpoints',
  create_endpoint: 'Create endpoint',
  name: 'Name',
  group: 'Group',
  type: 'Type',
  status: 'Status',
  response_time: 'Response Time',
  consumed: 'Consumed',
  balance: 'Balance',
  priority: 'Priority',
  actions: 'Actions',
  baidu_wenxin_qianfan: 'Baidu Wenxin Qianfan',
  alibaba_tongyi_qianwen: 'Alibaba Tongyi Qianwen',
  iflytek_stellar_cognition: 'iFLYTEK Stellar Cognition',
  zhipu_chatglm: 'Zhipu ChatGLM',
  '360_intelligence_brain': '360 Intelligence Brain',
  tencent_hunyuan: 'Tencent Hunyuan',
  baichuan_large_model: 'Baichuan Large Model',
  zero_one_all_things: 'Zero One All Things',
  jumpspace_stars: 'Jumpspace Stars',
  custom_endpoint: 'Custom Endpoint',
  knowledge_base: 'Knowledge Base',
  proxy: 'Proxy',
  priority: 'Priority',
  edit: 'Edit',
  delete: 'Delete',
  edit_endpoint: 'Edit Endpoint',
  endpoint_type: 'Endpoint Type',
  endpoint_name: 'Endpoint Name',
  please_name_endpoint: 'Please name the endpoint',
  endpoint_api_address: 'Endpoint API Address',
  optional_transit_api_address: 'Optional, please enter the transit API address, e.g., through Cloudflare transit',
  user_group: 'User Group',
  select_user_group: 'Please select the user group supported by this endpoint',
  model: 'Model',
  select_supported_models: 'Please select the models supported by this endpoint',
  fill_in_supported_models: 'Fill in the models supported by the endpoint',
  fill_in_all_models: 'Fill in all models',
  key: 'Key',
  enter_auth_key: 'Please enter the authentication key for the endpoint',
  model_mapping_relationship: 'Model Mapping Relationship',
  enter_model_mapping:
    'Please enter the model mapping relationship to be modified, format: api request model ID: actual model ID forwarded to endpoint, represented as a JSON array, e.g.: {"gpt-3.5": "gpt-35"}',
  priority_cannot_be_negative: 'Priority cannot be less than 0',
  endpoint_test_success: 'Endpoint {{itemName}} test succeeded, took {{time}} seconds.',
  balance_update_success: 'Balance updated successfully!',
  unknown: 'Unknown',
  enabled: 'Enabled',
  manual_disabled: 'This endpoint has been manually disabled',
  auto_disabled: 'This endpoint has been automatically disabled by the system',
  click_update_balance: 'Click to update balance',
  delete_endpoint: 'Delete endpoint',
  confirm_delete_endpoint: 'Are you sure you want to delete this endpoint?',
  close: 'Close',
  unsupported: 'Unsupported',
  seconds: 'Seconds',
  last_test_time: 'Last test time:',
  not_tested: 'Not tested',
  click_test: 'Click to test',
  copy_model_name_success: 'Model name copied successfully!',
  name_cannot_be_empty: 'Name cannot be empty',
  endpoint_cannot_be_empty: 'Endpoint cannot be empty',
  key_cannot_be_empty: 'Key cannot be empty',
  model_cannot_be_empty: 'Model cannot be empty',
  user_group_cannot_be_empty: 'User group cannot be empty',
  endpoint_api_address_cannot_be_empty: 'Endpoint API address cannot be empty',
  must_be_valid_json: 'Must be a valid JSON string',
  endpoint_update_success: 'Endpoint updated successfully!',
  endpoint_create_success: 'Endpoint created successfully!',
  custom_placeholder: 'Custom: Click or press Enter to input',
  edit_endpoint: 'Edit endpoint',
  fill_supported_models: 'Fill in supported models',
  fill_all_models: 'Fill in all models',
  one_key_per_line: 'One key per line',
  batch_add: 'Batch add',
  cancel: 'Cancel',
  submit: 'Submit',
  other_parameters: 'Other Parameters',
  select_endpoint_type: 'Please select an endpoint type',
  name_endpoint: 'Please name the endpoint',
  optional_transfer_api_address: 'Optional: Please enter the transfer API address, such as through Cloudflare',
  enter_auth_key: "Please enter the endpoint's authentication key",
  select_supported_models: 'Please select the models supported by this endpoint',
  enter_model_mapping:
    'Please enter the model mapping to be modified, in the format: api request model ID: actual model ID forwarded to the endpoint, using JSON array, for example: {"gpt-3.5": "gpt-35"}',
  select_supported_user_groups: 'Please select the user groups supported by this endpoint',
  default_api_version: 'Default API Version',
  fill_azure_openai_endpoint: 'Please fill in AZURE_OPENAI_ENDPOINT',
  enter_default_api_version: 'Please enter the default API version, for example: 2024-03-01-preview',
  enter_credentials: 'Enter in the format: APIKey|SecretKey',
  plugin_parameters: 'Plugin Parameters',
  enter_plugin_parameters: 'Please enter plugin parameters, i.e., the value of X-DashScope-Plugin request header',
  version_number: 'Version Number',
  enter_version_number: 'Enter the version number, for example: v3.1',
  enter_credentials_format: 'Enter in the format: APIKey-AppId, for example: fastgpt-0sp2gtvfdgyi4k30jwlgwf1i-64f335d84283f05518e9e041',
  enter_credentials_format_alt: 'Enter in the format: AppId|SecretId|SecretKey',
  enter_version_number_alt: 'Enter the version number, for example: v1',
  create_token: 'Create API Keys',
  search_token_name: 'Search for API Keys name...',
  remaining_quota: 'Remaining Quota',
  creation_time: 'Creation Time',
  expiry_time: 'Expiry Time',
  copied_to_clipboard: 'Copied to clipboard!',
  enabled: 'Enabled',
  disabled: 'Disabled',
  expired: 'Expired',
  exhausted: 'Exhausted',
  unlimited: 'Unlimited',
  never_expires: 'Never Expires',
  copy_token: 'Copy API Keys',
  edit_token: 'Edit API Keys',
  token_quota_note:
    "Note that the token quota only limits the maximum usage of the token itself. Actual usage is subject to the account's remaining quota.",
  model_scope: 'Model Scope',
  model_scope_elect: 'Please input model scope',
  select_allowed_models: 'Select the models allowed for use. Leave blank for no restriction.',
  ip_restriction: 'IP Restriction',
  enter_allowed_ip_ranges: 'Enter allowed IP ranges, e.g., 192.168.0.0/24. Use commas to separate multiple ranges.',
  invalid_date: 'Invalid Date',
  quota: 'Quota',
  unlimited_quota: 'Unlimited Quota',
  name_required: 'Name cannot be empty',
  must_be_greater_than_or_equal_to_zero: 'Must be greater than or equal to 0',
  token_update_success: 'Token updated successfully!',
  token_create_success: 'Token created successfully! Please click copy on the list page to obtain the token.',
  never_expires: 'Never Expires',
  equivalent_amount: 'Equivalent Amount',
  token_name: 'API Keys Name',
  model_name: 'Model Name',
  start_time: 'Start Time',
  end_time: 'End Time',
  endpoint_id: 'Endpoint ID',
  user_name: 'User Name',
  refresh_clear_search: 'Refresh/Clear Search Conditions',
  search: 'Search',
  all: 'All',
  recharge: 'Recharge',
  consume: 'Consume',
  manage: 'Manage',
  system: 'System',
  clear: 'Clear',
  today: 'Today',
  time: 'Time',
  must_be_greater_than_or_equal_to_1: 'Must be greater than or equal to 1',
  coupon_code_update_success: 'Coupon code updated successfully!',
  coupon_code_create_success: 'Coupon code created successfully!',
  edit_coupon_code: 'Edit Coupon Code',
  create_coupon_code: 'Create Coupon Code',
  search_coupon_code_id_name: 'Search by Coupon Code ID and Name...',
  redeem_time: 'Redemption Time',
  used: 'Used',
  not_yet_redeemed: 'Not Yet Redeemed',
  delete_coupon_code: 'Delete Coupon Code',
  confirm_delete_coupon_code: 'Are you sure you want to delete the coupon code {{itemName}}?',
  quantity: 'Quantity',
  create_model: 'Create Model',
  search_user: 'Search by user ID, username, display name, and email address...',
  delete_success: 'Deletion successful!',
  model_tag: 'Model Tag',
  model_release_time: 'Model Release Time',
  sort: 'Sort',
  is_trial: 'Is Trial',
  is_disabled: 'Is Disabled',
  category: 'Category',
  requested_model_name: 'Requested Model Name',
  model_config: 'Model Configuration',
  delete_user_confirmation: 'Are you sure you want to delete user {{name}}?',
  delete_model_confirmation: 'Are you sure you want to delete model {{name}}?',
  delete_model_tag_confirmation: 'Are you sure you want to delete model tag {{name}}？',
  delete_user: 'Delete User',
  delete_model: 'Delete Model',
  delete_model_tag: 'Delete Model Tag',
  model_config_update_success: 'Model configuration updated successfully!',
  load_model_config_failed: 'Failed to load model configuration',
  edit_model_config: 'Edit Model Configuration',
  edit_model: 'Edit Model',
  load_model_failed: 'Failed to load model',
  model_name_cannot_be_empty: 'Model name cannot be empty',
  release_date_cannot_be_empty: 'Release date cannot be empty',
  description_cannot_be_empty: 'Description cannot be empty',
  model_details_cannot_be_empty: 'Model details cannot be empty',
  model_update_successful: 'Model updated successfully!',
  model_creation_successful: 'Model created successfully!',
  release_time: 'Release Time',
  select_label: 'Select Label',
  model_category: 'Model Category',
  search_model_tag_name: 'Search model tag name...',
  tag_name_min_length: 'Tag name must be at least 1 character',
  tag_name_required: 'Tag name cannot be empty',
  tag_type_required: 'Tag type cannot be empty',
  tag_value_required: 'Tag value cannot be empty',
  enter_tag_name: 'Please enter the tag name',
  enter_tag_type: 'Please enter the tag type',
  tag_update_success: 'Tag updated successfully!',
  tag_create_success: 'Tag created successfully!',
  edit_tag: 'Edit Tag',
  new_tag: 'New Tag',
  tag_name: 'Tag Name',
  tag_value: 'Tag Value',
  tag_type: 'Tag Type',
  regular_user: 'Regular User',
  admin: 'Administrator',
  super_admin: 'Super Administrator',
  unknown_identity: 'Unknown Identity',
  create_user: 'Create User',
  search_users_placeholder: 'Search by user ID, username, display name, or email address...',
  statistics_info: 'Statistics Information',
  user_roles: 'User Roles',
  binding: 'Binding',
  request_count: 'Request Count',
  unbound: 'Unbound',
  set_as_admin: 'Set as Admin',
  cancel_admin: 'Cancel Admin',
  edit_user: 'Edit User',
  display_name: 'Display Name',
  password: 'Password',
  username_min_length: 'Username must be at least 3 characters long',
  username_required: 'Username is required',
  password_min_length: 'Password must be at least 8 characters long',
  password_required: 'Password is required',
  user_group_required: 'User group is required',
  quota_non_negative: 'Quota cannot be less than 0',
  enter_username: 'Please enter a username',
  enter_password: 'Please enter a password',
  user_update_success: 'User updated successfully!',
  user_creation_success: 'User created successfully!',
  wechat_binding_success: 'WeChat account binding successful!',
  token_reset_and_copied: 'Token has been reset and copied to clipboard',
  user_info_update_success: 'User information updated successfully!',
  bind_wechat_account: 'Bind WeChat account',
  bind_github_account: 'Bind GitHub account',
  account_binding: 'Account binding',
  enter_display_name: 'Please enter a display name',
  note_token_usage: 'Note: The generated token is for system management and not for accessing PerfXCloud services. Please be aware.',
  your_access_token: 'Your access token is:',
  secure_storage_warning: 'Please keep it safe. If leaked, reset immediately.',
  reset_access_token: 'Reset access token',
  generate_access_token: 'Generate access token',
  dangerous_operation: 'Dangerous operation',
  delete_account_warning: 'You are about to delete your account. All data will be cleared and cannot be recovered.',
  confirm: 'Confirm',
  change_phone_number: 'Change phone number',
  bind_phone_number: 'Bind phone number',
  personal_information: 'Personal Information',
  bind_feishu_account: 'Bind Feishu Account',
  change_email: 'Change Email',
  bind_email: 'Bind Email',
  other: 'Other',
  please_enter_valid_email: 'Please enter a valid email address',
  email_cannot_be_empty: 'Email cannot be empty',
  verification_code_cannot_be_empty: 'Verification code cannot be empty',
  email_binding_successful: 'Email account bound successfully!',
  please_enter_email: 'Please enter your email',
  email: 'Email',
  resend_countdown: 'Resend ({{countdown}})',
  get_verification_code: 'Get Verification Code',
  verification_code: 'Verification Code',
  error_too_many_requests: 'Error: Too many requests. Please try again later!',
  error: 'Error:',
  go_to_first_page: 'Go to first page',
  go_to_last_page: 'Go to last page',
  go_to_next_page: 'Go to next page',
  go_to_previous_page: 'Go to previous page',
  phone_number_format_invalid: 'Phone number format is incorrect',
  phone_number_required: 'Phone number is required',
  verification_code_required: 'Verification code is required',
  phone_number_bound_successfully: 'Phone number bound successfully!',
  please_enter_phone_number: 'Please enter your phone number',
  phone_number: 'Phone number',
  log_settings: 'Log Settings',
  enable_log_consumption: 'Enable Log Consumption',
  clear_history_logs: 'Clear History Logs',
  log_cleanup_time: 'Log Cleanup Time',
  monitoring_settings: 'Monitoring Settings',
  disable_endpoint_on_failure: 'Automatically disable endpoint on failure',
  enable_endpoint_on_success: 'Automatically enable endpoint on success',
  max_response_time: 'Maximum Response Time',
  quota_alert_threshold: 'Quota Alert Threshold',
  unit_seconds: 'Unit: Seconds',
  endpoint_test_timeout_message: 'Automatically disable endpoint if this time is exceeded during full endpoint tests',
  quota_alert_message: 'Send email notification when below this quota',
  save_monitoring_settings: 'Save Monitoring Settings',
  save_quota_settings: 'Save Quota Settings',
  quota_settings: 'Quota Settings',
  new_user_initial_quota: 'New User Initial Quota',
  pre_deducted_request_quota: 'Pre-deducted Request Quota',
  invite_new_user_reward_quota: 'Invitation Reward Quota for New Users',
  reward_quota_for_invite_code: 'Reward Quota for New Users Using Invitation Code',
  multiplier_settings: 'Multiplier Settings',
  model_multiplier: 'Model Multiplier',
  completion_multiplier: 'Completion Multiplier',
  group_multiplier: 'Group Multiplier',
  settings_success: 'Settings updated successfully!',
  invalid_model_multiplier: 'Model multiplier is not a valid JSON string',
  invalid_group_multiplier: 'Group multiplier is not a valid JSON string',
  invalid_completion_multiplier: 'Completion multiplier is not a valid JSON string',
  save_success: 'Save successful!',
  logs_cleared: '{{data}} logs have been cleared!',
  logs_clear_failure: 'Log clearing failed:',
  general_settings: 'General Settings',
  recharge_link: 'Recharge Link',
  example_recharge_link: 'e.g., Purchase link for card issuing websites',
  example_deployment_link: 'e.g., Deployment address for ChatGPT Next Web',
  unit_quota: 'Unit Quota',
  currency_to_quota: 'Quota per unit of currency',
  retry_count: 'Retry Count',
  display_as_currency: 'Display quota in currency',
  billing_api_token_quota: 'Display token quota in Billing related API instead of user quota',
  approximate_token_estimation: 'Estimate token count approximately to reduce computation',
  save_general_settings: 'Save General Settings',
  operation_settings: 'Operation Settings',
  system_settings: 'System Settings',
  other_settings: 'Other Settings',
  chat_link: 'Chat Link',
  unit_seconds_auto_disable: 'Unit seconds, if the endpoint test exceeds this time, it will be automatically disabled',
  example_100: 'e.g., 100',
  example_2000: 'e.g., 2000',
  example_1000: 'e.g., 1000',
  example: 'example:',
  request_adjustment: 'Adjust after request completion',
  json_text_example: 'For a JSON text, where the key is the model name and the value is the multiplier.',
  json_text_example_description:
    'For a JSON text, where the key is the model name and the value is the multiplier. This multiplier setting represents the ratio of completion multiplier to prompt multiplier for the model. Using this setting will override the internal ratio of the One API.',
  json_text_group_multiplier: 'For a JSON text, where the key is the group name and the value is the multiplier.',
  save_multiplier_settings: 'Save multiplier settings',
  chinese: '中文',
  english: 'English',
  document_center: 'Docs',
  logout: 'Logout',
  server_address: 'Server Address',
  document_address: 'Document Address',
  update_server_address: 'Update Server Address',
  update_document_address: 'Update Document Address',
  configure_login_registration: 'Configure Login & Registration',
  allow_password_login: 'Allow Login with Password',
  password_registration_email_verification: 'Email Verification Required for Password Registration',
  allow_github_login_registration: 'Allow Login & Registration via GitHub',
  allow_google_login_registration: 'Allow Login & Registration via Google',
  allow_wechat_login_registration: 'Allow Login & Registration via WeChat',
  allow_new_user_registration: "Allow New User Registration (If set to 'No', new users cannot register in any way)",
  enable_turnstile_verification: 'Enable Turnstile Verification',
  enable_phone_verification_login_registration: 'Enable Phone Verification Login & Registration',
  configure_email_domain_whitelist: 'Configure Email Domain Whitelist',
  prevent_temporary_email_registration: 'Prevent Registration Using Temporary Emails',
  enable_email_domain_whitelist: 'Enable Email Domain Whitelist',
  allowed_email_domains: 'Allowed Email Domains',
  save_email_domain_whitelist_settings: 'Save Email Domain Whitelist Settings',
  configure_smtp: 'Configure SMTP',
  support_email_sending: 'Support Email Sending for the System',
  smtp_server_address: 'SMTP Server Address',
  smtp_port: 'SMTP Port',
  smtp_default: 'Default:',
  smtp_account: 'SMTP Account',
  smtp_account_description: 'Usually the email address',
  smtp_sender_email: 'SMTP Sender Email',
  smtp_sender_email_description: 'Usually consistent with the email address',
  smtp_credentials: 'SMTP Credentials',
  sensitive_info_hidden_description: 'Sensitive information will not be sent to the frontend',
  save_smtp_settings: 'Save SMTP Settings',
  configure_github_oauth: 'Configure GitHub OAuth App',
  configure_google_oauth: 'Configure Google OAuth App',
  input_github_oauth_id: 'Enter your registered GitHub OAuth APP ID (Sensitive information will not be sent to the frontend)',
  save_github_oauth_settings: 'Save GitHub OAuth Settings',
  save_google_oauth_settings: 'Save Google OAuth Settings',
  configure_feishu_oauth: 'Configure Feishu OAuth Login to support Login & Registration via Feishu. Click here to manage your Feishu App',
  input_feishu_oauth_homepage: 'Enter the homepage link',
  input_feishu_oauth_redirect_url: 'Enter the redirect URL',
  save_feishu_oauth_settings: 'Save Feishu OAuth Settings',
  configure_wechat_oauth_login: 'Configure WeChat Authorization Login',
  support_wechat_login_registration: 'Supports login and registration via WeChat,',
  allow_password_registration: 'Allow Registration with Password',
  github_oauth_support_description: 'Used to support login and registration via GitHub',
  google_oauth_support_description: 'Used to support login and registration via Google',
  click_here: 'Click here',
  manage_your_github_oauth_app: 'Manage your GitHub OAuth App',
  manage_your_google_oauth_app: 'Manage your Google OAuth App',
  fill_in: 'Fill in',
  support_feishu_login_register: 'Used to support login and registration through Feishu,',
  manage_feishu_app: 'manage your Feishu application.',
  input_app_id: 'Enter App ID',
  configure: 'Configure',
  learn_more: 'Learn more',
  access_credential: 'Access Credential',
  wechat_official_account_qr_code: 'WeChat Official Account QR Code Link',
  input_image_link: 'Enter an image link',
  save_wechat_server_settings: 'Save WeChat Server Settings',
  alert_push_info: 'For pushing alert information,',
  message_pusher_address: 'Message Pusher Address',
  save_message_pusher_settings: 'Save Message Pusher Settings',
  user_verification_info: 'To support user verification,',
  manage_turnstile_sites: 'Manage your Turnstile Sites, recommend choosing Invisible Widget Type',
  input_turnstile_site_key: 'Enter your registered Turnstile Site Key',
  save_turnstile_settings: 'Save Turnstile Settings',
  disable_password_login_warning:
    'Disabling password login will prevent all users (including administrators) who have not bound other login methods from logging in with a password. Are you sure you want to proceed?',
  warning: 'Warning',
  emergency_notice: 'Emergency Notice',
  maintenance_notice: 'Maintenance Notice',
  promotion_notice: 'Promotion Notice',
  policy_notice: 'Policy Notice',
  latest_version: 'Already up to date:',
  check_update: 'Check for updates',
  announcement_type: 'Announcement Type',
  announcement: 'Announcement',
  enter_new_announcement_content: 'Enter new announcement content here, supports Markdown & HTML code',
  save_announcement: 'Save Announcement',
  personalized_settings: 'Personalized Settings',
  system_name: 'System Name',
  enter_system_name: 'Enter system name here',
  set_system_name: 'Set System Name',
  theme_name: 'Theme Name',
  enter_theme_name: 'Enter theme name',
  set_theme: 'Set Theme (Restart to apply)',
  logo_image_address: 'Logo Image Address',
  enter_logo_image_address: 'Enter Logo image address here',
  set_logo: 'Set Logo',
  homepage_content: 'Homepage Content',
  enter_homepage_content:
    'Enter homepage content here, supports Markdown & HTML code. After setting, the homepage status information will no longer be displayed. If a link is entered, it will be used as the src attribute of an iframe, allowing you to set any webpage as the homepage.',
  save_homepage_content: 'Save Homepage Content',
  about: 'About',
  enter_about_content:
    'Enter new about content here, supports Markdown & HTML code. If a link is entered, it will be used as the src attribute of an iframe, allowing you to set any webpage as the about page.',
  save_about: 'Save About',
  remove_copyright_notice:
    "Removing One API's copyright notice must be authorized first. Project maintenance requires considerable effort. If this project is meaningful to you, please actively support it.",
  footer: 'Footer',
  enter_new_footer: 'Enter new footer here, leave empty to use the default footer, supports HTML code',
  set_footer: 'Set Footer',
  model_api_configuration: 'Model API Configuration Settings',
  public_token: 'Public Token',
  enter_public_token: 'Enter public token',
  model_api_access_address: 'Model API Access Address',
  enter_model_api_access_address: 'Enter model API access address',
  chatbot_access_address: 'ChatBot Access Address',
  enter_chatbot_access_address: 'Enter ChatBot access address',
  model_configuration_settings: 'Model Configuration Settings',
  new_version: 'New Version:',
  view_on_github: 'View on GitHub',
  public_security: 'Beijing Public Security No.: 11010802038875',
  icp_record: 'Beijing ICP No.: 2020035859-1',
  copyright: 'Copyright © Perfxlab All Rights Reserved',
  home: 'Home',
  phone_login: 'Phone Login',
  account_login: 'Account Login',
  console: 'Console',
  login: 'Login',
  password_reset: 'Password Reset',
  processing: 'Processing...',
  operation_failed_redirecting_to_login: 'Operation failed, redirecting to login page...',
  retrying_message: 'An error occurred, retrying {{count}} time(s)...',
  github_login: 'GitHub Login',
  google_login: 'Google Login',
  feishu_login: 'Feishu Login',
  register: 'Register',
  email_register: 'Email Register',
  already_have_account: 'Already have an account? Click to login',
  phone_register: 'Phone Register',
  password_reset_confirm: 'Password Reset Confirmation',
  feishu_login: 'Feishu Login',
  phone_login: 'Phone Login',
  register: 'Sign up',
  already_have_account: 'Already have an account? Click to login',
  phone_register: 'Phone Register',
  password_reset_confirm: 'Password Reset Confirmation',
  login_with_github: 'Login with GitHub',
  login_with_wechat: 'Login with WeChat',
  login_with_google: 'Login with Google',
  login_with_feishu: 'Login with Feishu',
  username_length: 'Username must be between 3 and 40 characters',
  username_required: 'Username is required',
  password_length: 'Password must be between 8 and 20 characters',
  password_required: 'Password is required',
  agree_to_terms: 'Please agree to the terms first',
  username_email_phone: 'Username / Email',
  agree_and_read: 'Agree to the ',
  user_agreement: 'User Agreement',
  forgot_password: 'Forgot Password?',
  enter_phone_number: 'Please enter phone number',
  phone_verification_required: 'Phone verification code is required',
  unregistered_phone_creates_account: 'Unregistered phone number will automatically create an account',
  passwords_do_not_match: 'Passwords do not match',
  retry_turnstile: "Please wait a few seconds and try again, Turnstile is checking the user's environment!",
  confirm_password_required: 'Confirm password is required',
  invalid_email: 'Must be a valid email address',
  email_required: 'Email is required',
  confirm_password: 'Confirm Password',
  start_your_exclusive_experience: 'Start your exclusive experience from ',
  start: 'Start.',
  please_enter_phone_number: 'Please enter phone number',
  phone_verification_code_required: 'Phone verification code is required',
  reset_email_sent_success: 'Reset email sent successfully, please check your inbox!',
  enter_verification_code: 'Please enter the verification code',
  enter_reset_password: 'Please enter the password you want to reset',
  reset_password_success: 'Password reset successful!',
  reset_sms_code_success: 'SMS verification code for reset sent successfully, please check your SMS!',
  sms_code_success: 'SMS verification code for reset sent successfully, please check your SMS!',
  email_code_success: 'Email sent successfully, please check your inbox!',
  invalid_phone_number: 'Must be a valid phone number',
  new_password: 'New Password',
  retry: 'Retry ({{countdownTime}})',
  newPasswordCopied: 'New password copied to clipboard:',
  invalidLink: 'Invalid link',
  yourNewPasswordIs: 'Your new password is: ',
  pleaseLoginChangePassword: 'Please log in and change your password promptly',
  clickResetPassword: 'Click to reset password',
  supported_chip_types: 'Supported Chip Types',
  model_description: 'Model Description',
  go_back: 'Go Back',
  total_models: 'Total Models',
  large_language_model: 'Large Language Model',
  embedding_model: 'Embedding Model',
  changsha_shuguang_center: 'Changsha Shuguang Center',
  xian_shuguang_center: "Xi'an Shuguang Center",
  dianjun_center: 'Dianjun Center',
  tianjin_center: 'Tianjin Center',
  wuhan_center: 'Wuhan Center',
  gansu_center: 'Gansu Center',
  neimenggu_center: 'Inner Mongolia Center',
  online: 'Online',
  offline: 'Offline',
  popular_models_recommendation_large_language_models: 'Popular Models Recommendation',

  no_search_results: 'No Search Results',
  adjust_search_or_filters: "Try adjusting your search or filters to find what you're looking for.",
  clear_filters: 'Clear Filters',
  disclaimer_title: 'Disclaimer',
  disclaimer_content: `Due to technical limitations, the platform cannot fully guarantee the legality, authenticity, accuracy, or completeness of the outputs obtained through this service, despite reasonable efforts. You should make your own judgment regarding the outputs from this service and assume all risks associated with using the content.
            We remind you that, unless explicitly stipulated by law, the platform will not be liable for any losses or damages arising from the aforementioned risks. The outputs under this service do not constitute professional advice and cannot replace answers from professionals in fields such as law, medicine, finance, etc. The views presented are limited to the results derived from the algorithm’s access to a limited dataset and do not represent the platform’s stance.
            Due to the nature of machine learning, identical inputs may produce different outputs. Please exercise caution and discernment.`,
  tryNow: 'Try Now',
  copy_code: 'Copy Code',
  code_copied_successfully: 'Code copied successfully!',
  register_success: 'Register Success!',
  bindingSuccess: 'Binding successful!',
  user_unauthorized: 'User not authorized',
  loginSuccess: 'Login successful!',
  pleaseEnterContent: 'Please enter content',
  modelContextLength: 'Model Context Length',
  no_data: 'No Data',
  top_up_center: 'Top-up Center ',
  rechargeAndInviteLogs:
    "Please check the recharge records and invitation records in the logs. For recharge records, select the type 'Recharge' in the logs; for invitation records, select 'System' in the logs.",
  currentBalance: 'Current Balance',
  redemptionCode: 'Redemption Code',
  pleaseEnterRedemptionCode: 'Please enter the redemption code',
  redeeming: 'Redeeming...',
  redeem: 'Redeem',
  noRedemptionCode: 'Don’t have a redemption code? Click to get a redemption code:',
  getRedemptionCode: 'Get Redemption Code',
  adminNoRechargeLink: 'Super Administrator has not set up a recharge link!',
  requestFailed: 'Request Failed',
  rechargeSuccessful: 'Recharge Successful!',
  pleaseEnterRechargeCode: 'Please enter the recharge code!',
  'menus.panelMenus.image': 'Image',
  'menus.panelMenus.link': 'Link',
  'menus.panelMenus.code': 'Code',
  'menus.panelMenus.video': 'Video',
  'menus.panelMenus.table': 'Table',
  'menus.panelMenus.emotion': 'Emotion',
  'menus.panelMenus.math': 'Math',
  'menus.panelMenus.lineHeight': 'Line Height',
  'menus.panelMenus.fontFamily': 'Font Family',
  'menus.panelMenus.fontSize': 'Font Size',
  'menus.panelMenus.justify': 'Justify',
  'menus.panelMenus.textStyle': 'Text Style',
  order_manage: 'Order Managements',
  order_number: 'Order Number',
  recharge_amount: 'Recharge Amount',
  token_balance: 'Token Balance',
  order_status: 'Order Status',
  rejection_reason: 'Refund Rejection Reason',
  order_status_pending: 'Pending',
  order_status_paid_success: 'Payment Success',
  order_status_paid_failed: 'Payment Failure',
  order_status_cancelled: 'Cancelled',
  order_status_refund_request: 'Refund Request',
  order_status_refunding: 'Refunding',
  order_status_refund_success: 'Refund Success',
  order_status_refund_failed: 'Refund Failed',
  all: 'All',
  select_purchase_method: 'Select Purchase Method',
  choose_appropriate_way: 'Please choose an appropriate way to acquire the Token',
  purchase_token: 'Purchase Token',
  redeem_token: 'Redeem Token',
  refund: 'Refund',
  agree_refund: 'Agree to refund',
  refusal_refund: 'Refusal of refund',
  refund_approved: 'Refund approved, processing...',
  refund_request_success: 'Refund request successful',
  refund_enter_reason: 'Please enter the refund reason',
  refund_request: 'Request Refund',
  refund_reason: 'Refund Reason',
  refund_confirm_approve: 'Do you approve the refund?',
  refund_reject: 'Reject Refund',
  refund_reject_reason: 'Reject Refund Reason',
  refund_enter_reject_reason: 'Please enter the reject refund reason',
  refund_reject_success: 'Refund rejected successfully',
  pay_amount: 'Payment amount',
  recharge: 'Recharge',
  view_price: 'View prices',
  pay_method: 'Payment method',
  token_tips: 'Based on the latest output price estimate, for reference only, the actual price at the time of call shall prevail',
  bank: 'Bank Card',
  custom: 'Custom',
  card_number: 'Card Number',
  about_equal: 'about equal to',
  expiration_date: 'Expiration Date',
  openAI_multimodal: 'OpenAI Multimodal',
  not_enough_quota: 'Insufficient refund balance',
  error_unexpected: 'An unexpected error occurred:',
  error_payment_failed: 'Payment failed: ',
  error_client_secret_not_found: 'clientSecret not found!',
  error_amount_minimum: 'Amount must be at least $0.50',
  error_complete_cvc: 'Please complete the CVC.',
  error_complete_expiry_date: 'Please complete the expiry date.',
  error_complete_card_number: 'Please complete the card number.'
};

export default en;
