// material-ui
import { Link, Container, Box, Grid, useTheme } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import NationalEmblemImg from 'assets/images/national_emblem.png';
import { useHeaderVisibility } from 'hooks/useHeaderVisibility';
import { t } from 'i18next';
// ==============================|| FOOTER - AUTHENTICATION 2 & 3 ||============================== //

const Footer = ({ showFilings }) => {
  const siteInfo = useSelector((state) => state.siteInfo);
  const displayOneApi = useHeaderVisibility();
  const theme = useTheme();
  return (
    <>
      {showFilings && (
        <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '64px' }}>
          <Box sx={{ textAlign: 'center' }}>
            {siteInfo.footer_html ? (
              <div className="custom-footer" dangerouslySetInnerHTML={{ __html: siteInfo.footer_html }}></div>
            ) : (
              <>
                {/* {!displayOneApi && (
              <div style={{ marginBottom: '10px', color: '#aaa' }}>
                本项目基于
                <Link href="https://github.com/songquanpeng/one-api" target="_blank">
                  oneapi
                </Link>
                构建
              </div>
            )} */}

                <Grid
                  sx={{
                    marginBottom: '40px',
                    display: 'flex',
                    justifyContent: 'center',
                    [theme.breakpoints.up('md')]: {
                      flexDirection: 'row',
                      marginBottom: '0px'
                    }
                  }}
                  style={{
                    height: '15px',
                    lineHeight: '15px',
                    display: 'flex',
                    alignItems: 'center',
                    color: '#aaa',
                    justifyContent: 'center',
                    marginTop: '20px',
                    flexWrap: 'wrap'
                  }}
                >
                  <span>{t('copyright')}</span>
                </Grid>
              </>
            )}
          </Box>
        </Container>
      )}
    </>
  );
};

export default Footer;
