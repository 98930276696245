// assets
import { IconChartArea, IconMenuOrder } from '@tabler/icons-react';
import {
  IconDashboard,
  IconSitemap,
  IconArticle,
  IconCoin,
  IconAdjustments,
  IconKey,
  IconGardenCart,
  IconUser,
  IconUserScan,
  IconLayoutGrid,
  IconDevicesPlus,
  IconUserHeart,
  IconBoxModel,
  IconTag,
  IconPictureInPicture,
  IconMessage,
  IconDeviceAudioTape
} from '@tabler/icons-react';
import i18n from 'i18next';
// constant
const icons = {
  IconDashboard,
  IconLayoutGrid,
  IconSitemap,
  IconArticle,
  IconCoin,
  IconAdjustments,
  IconKey,
  IconGardenCart,
  IconUser,
  IconUserScan,
  IconDevicesPlus,
  IconChartArea
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const panel = {
  id: 'panel',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: i18n.t('dashboard'),
      type: 'item',
      url: '/panel/dashboard',
      icon: icons.IconDashboard,
      breadcrumbs: false,
      isAdmin: false
    },
    {
      id: 'statistics',
      title: i18n.t('statistics'),
      type: 'item',
      url: '/panel/statistics',
      icon: IconChartArea,
      breadcrumbs: false,
      isAdmin: true
    },
    {
      id: 'modelsquare',
      title: i18n.t('modelsquare'),
      type: 'item',
      url: '/panel/modelsquare',
      icon: icons.IconLayoutGrid,
      breadcrumbs: false,
      isAdmin: false
    },
    // {
    //   id: 'model-adjustment',
    //   title: '模型微调',
    //   type: 'item',
    //   url: '',
    //   icon: IconSettingsBolt,
    //   breadcrumbs: false,
    //   isAdmin: false,
    //   disabled: true
    // },
    // {
    //   id: 'model-deploy',
    //   title: '模型部署',
    //   type: 'item',
    //   url: '',
    //   icon: icons.IconDevicesPlus,
    //   breadcrumbs: false,
    //   isAdmin: false,
    //   disabled: true
    // },
    // {
    //   id: 'user_index_distribution',
    //   title: '数据面板管理',
    //   type: 'item',
    //   url: '/panel/user_index_distribution',
    //   icon: IconEaseInControlPoint,
    //   breadcrumbs: false,
    //   isAdmin: true
    // },
    {
      id: 'exploration_center',
      title: i18n.t('exploration_center'),
      // type: 'collapse',
      type: 'item',
      url: '/panel/exploration_center',
      icon: IconUserHeart,
      breadcrumbs: false,
      isAdmin: false
      // children: [
      //   {
      //     id: 'chat_bot',
      //     title: i18n.t('chat_bot'),
      //     type: 'item',
      //     url: '/panel/exploration_center',
      //     icon: IconMessage,
      //     breadcrumbs: false,
      //     isAdmin: false
      //   },
      //   {
      //     id: 'text2image',
      //     title: i18n.t('text2image'),
      //     type: 'item',
      //     url: '/panel/exploration_center/text2img',
      //     icon: IconPictureInPicture,
      //     breadcrumbs: false,
      //     isAdmin: false
      //   },
      //   {
      //     id: 'text2language',
      //     title: i18n.t('text2language'),
      //     type: 'item',
      //     url: '/panel/exploration_center/chattts',
      //     icon: IconDeviceAudioTape,
      //     breadcrumbs: false,
      //     isAdmin: false
      //   }
      // ]
    },
    {
      id: 'channel',
      title: i18n.t('channel'),
      type: 'item',
      url: '/panel/channel',
      icon: icons.IconSitemap,
      breadcrumbs: false,
      isAdmin: true
    },
    {
      id: 'token',
      title: i18n.t('api_keys'),
      type: 'item',
      url: '/panel/token',
      icon: icons.IconKey,
      breadcrumbs: false
    },
    {
      id: 'log',
      title: i18n.t('log'),
      type: 'item',
      url: '/panel/log',
      icon: icons.IconArticle,
      breadcrumbs: false
    },
    {
      id: 'redemption',
      title: i18n.t('redemption'),
      type: 'item',
      url: '/panel/redemption',
      icon: icons.IconCoin,
      breadcrumbs: false,
      isAdmin: true
    },
    {
      id: 'topup',
      title: i18n.t('top_up_center'),
      type: 'item',
      url: '/panel/topup',
      icon: icons.IconGardenCart,
      breadcrumbs: false
    },
    {
      id: 'modelsquare-manage',
      title: i18n.t('modelsquare_manage'),
      type: 'item',
      url: '/panel/modelsquare-manage',
      icon: IconBoxModel,
      breadcrumbs: false,
      isAdmin: true
    },
    {
      id: 'modeltag/manage',
      title: i18n.t('modeltag_manage'),
      type: 'item',
      url: '/panel/modeltag/manage',
      icon: IconTag,
      breadcrumbs: false,
      isAdmin: true
    },
    {
      id: 'order/manage',
      title: i18n.t('order_manage'),
      type: 'item',
      url: '/panel/order/manage',
      icon: IconMenuOrder,
      breadcrumbs: false,
      isAdmin: false
    },
    {
      id: 'user',
      title: i18n.t('user'),
      type: 'item',
      url: '/panel/user',
      icon: icons.IconUser,
      breadcrumbs: false,
      isAdmin: true
    },
    {
      id: 'profile',
      title: i18n.t('profile'),
      type: 'item',
      url: '/panel/profile',
      icon: icons.IconUserScan,
      breadcrumbs: false,
      isAdmin: false
    },
    {
      id: 'setting',
      title: i18n.t('setting'),
      type: 'item',
      url: '/panel/setting',
      icon: icons.IconAdjustments,
      breadcrumbs: false,
      isAdmin: true,
      isRoot: true
    }
  ]
};

export default panel;
