import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const Channel = Loadable(lazy(() => import('views/Channel')));
const Log = Loadable(lazy(() => import('views/Log')));
const Redemption = Loadable(lazy(() => import('views/Redemption')));
const Setting = Loadable(lazy(() => import('views/Setting')));
const Token = Loadable(lazy(() => import('views/Token')));
const Topup = Loadable(lazy(() => import('views/Topup')));
const User = Loadable(lazy(() => import('views/User')));
const Profile = Loadable(lazy(() => import('views/Profile')));
const NotFoundView = Loadable(lazy(() => import('views/Error')));

// dashboard routing
const Dashboard = Loadable(lazy(() => import('views/Dashboard')));
const ModelSquare = Loadable(lazy(() => import('views/ModelSquare')));
const ModelSquareManage = Loadable(lazy(() => import('views/ModelSquareManage')));
const ModelTagManage = Loadable(lazy(() => import('views/ModelTagManage')));
const OrderManage = Loadable(lazy(() => import('views/OrderManage')));
const ModelDetails = Loadable(lazy(() => import('views/ModelDetails')));
const ExplorationCenter = Loadable(lazy(() => import('views/ExplorationCenter')));
const ExplorationCenterText2Img = Loadable(lazy(() => import('views/ExplorationCenter/text2img')));
const ExplorationCenterChatTTS = Loadable(lazy(() => import('views/ExplorationCenter/chattts')));
const StatisticsPanel = Loadable(lazy(() => import('views/StatisticsPanel')));
const StatisticsPanelManage = Loadable(lazy(() => import('views/StatisticsPanelManage')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/panel',
  element: <MainLayout />,
  children: [
    {
      path: '',
      element: <Dashboard />
    },
    {
      path: 'statistics',
      element: <StatisticsPanel />
    },

    {
      path: 'dashboard',
      element: <Dashboard />
    },
    {
      path: 'modelsquare',
      element: <ModelSquare />
    },
    {
      path: 'modelsquare-manage',
      element: <ModelSquareManage />
    },
    {
      path: 'modeltag/manage',
      element: <ModelTagManage />
    },
    {
      path: 'order/manage',
      element: <OrderManage />
    },
    {
      path: 'user_index_distribution',
      element: <StatisticsPanelManage />
    },
    {
      path: 'exploration_center',
      element: <ExplorationCenter />
    },
    {
      path: 'exploration_center/text2img',
      element: <ExplorationCenterText2Img />
    },
    {
      path: 'exploration_center/chattts',
      element: <ExplorationCenterChatTTS />
    },
    {
      path: 'modelsquare/details/:id',
      element: <ModelDetails />
    },
    {
      path: 'channel',
      element: <Channel />
    },
    {
      path: 'log',
      element: <Log />
    },
    {
      path: 'redemption',
      element: <Redemption />
    },
    {
      path: 'setting',
      element: <Setting />
    },
    {
      path: 'token',
      element: <Token />
    },
    {
      path: 'topup',
      element: <Topup />
    },
    {
      path: 'user',
      element: <User />
    },
    {
      path: 'profile',
      element: <Profile />
    },
    {
      path: '404',
      element: <NotFoundView />
    }
  ]
};

export default MainRoutes;
