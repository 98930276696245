// 设置默认的语言
export const defaultLanguage = 'en';
export const getDefaultLanguage = () => {
  const language = localStorage.getItem('language');

  if (language) {
    return language;
  }
  return defaultLanguage;
};

export const setLanguage = (language) => {
  localStorage.setItem('language', language);
};
