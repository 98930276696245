// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en';
import zh from './locales/zh';
import { getDefaultLanguage } from './utils';

const resources = {
  en: {
    translation: en
  },
  zh: {
    translation: zh
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: getDefaultLanguage(), // 默认语言
  keySeparator: false,
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
